import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Intro = () => {
  const [offset, setOffset] = useState(0)
  useEffect(() => {
    function onScroll() {
      setOffset(window.scrollY / 3)
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  })
  return (
    <div className="intro">
      <div className="content white">
        <div
          className="sun"
          style={{
            transform: `translate(0, ${offset}px)`,
            textAlign: "center",
          }}
        >
          <StaticImage
            src="../../images/sun.svg"
            width={700}
            placeholder="blurred"
            alt="sun"
          />
        </div>
        <div className="earth">
          <StaticImage
            src="../../images/logo.png"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            alt="Maximum Overdrive"
            width={800}
            placeholder="blurred"
          />
          <p className="text-center f-lg">
            An 80’s band - <span className="bold">from the future!</span>
          </p>

          <div className="cta">
            <Link className="btn btn-primary" to="/#contact">
              Book Maximum Overdrive
            </Link>
          </div>
        </div>
      </div>
      <div
        className="stripes"
        style={{
          transform: `translate(0, ${offset}px)`,
        }}
      />
    </div>
  )
}

export default Intro
