import React from 'react'

import './testimonials.scss'

const Testimonials = ({ testimonials }) => (
  <div id="testimonials" className="white text-center">
    <h2 className="section-title">Testimonials</h2>
    {testimonials.map(({ author, text }) => (
      <div key={author + text} className="testimonial">
        <p className="text">"{text}"</p>
        <p className="author">{author}</p>
      </div>
    ))}
  </div>
)

export default Testimonials
