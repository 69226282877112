import React, { useState } from "react"

import "./song-list.scss"

const SongList = ({ songs }) => {
  // artist, artistReverse, title, titleReverse
  const [filterBy, setFilterBy] = useState("artist")
  const [searchText, setSearchText] = useState("")
  const sortedList = songs
    .sort((a, b) => {
      switch (filterBy) {
        case "artistReverse":
          return a.artist < b.artist ? 1 : -1
        case "title":
          return a.title < b.title ? -1 : 1
        case "titleReverse":
          return a.title < b.title ? 1 : -1
        default:
          return a.artist < b.artist ? -1 : 1
      }
    })
    .filter(song => {
      if (!searchText) {
        return song
      }

      return (
        song.title.toLowerCase().includes(searchText.toLowerCase()) ||
        song.artist.toLowerCase().includes(searchText.toLowerCase())
      )
    })

  return (
    <div className="white text-center" id="song-list">
      <h2 className="section-title">Song List</h2>
      <div className="max-width-content">
        <div className="filters">
          <div className="filter-group">
            <p>Sort by: </p>
            <select
              value={filterBy}
              onChange={e => setFilterBy(e.target.value)}
            >
              <option value="artist">Artist A-Z</option>

              <option value="artistReverse">Artist Z-A</option>
              <option value="title">Song A-Z</option>
              <option value="titleReverse">Song Z-A</option>
            </select>
          </div>

          <div className="filter-group">
            <p>Search: </p>
            <input
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
        </div>

        <div className="list">
          {sortedList.map((song, i) => (
            <div key={song.title + song.artist} className="song">
              <p>
                {song.title} - {song.artist}{" "}
                {i + 1 < sortedList.length ? (
                  <span className="divider">|</span>
                ) : (
                  ""
                )}
              </p>
            </div>
          ))}
        </div>
        <div className="message">
          <p className="bold">
            *WE ARE CONSTANTLY ADDING TO THIS LIST OF THE BEST OF 80S MUSIC. IF
            THERE IS A SONG YOU WANT TO HEAR FOR YOUR EVENT YOU CAN MAKE THE
            REQUEST WHEN BOOKING THE BAND.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SongList
