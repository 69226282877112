import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import Seo from '../components/seo'

import './index.scss'
import About from '../components/about/About'
import Live from '../components/live/Live'
import SongList from '../components/songList/SongList'
import Testimonials from '../components/testimonials/Testimonials'
import Contact from '../components/contact/Contact'
import Intro from '../components/intro/Intro'

const IndexPage = ({ data }) => {
  const pageData = data?.pageInfo?.frontmatter
  const testimonials = data?.testimonials?.edges?.map(edge => {
    return {
      id: edge?.node?.id,
      ...(edge?.node?.frontmatter || {}),
    }
  })
  return (
    <Layout socialLinks={pageData?.['social_links']}>
      <Seo title="Join the party. Live the 80s." />
      <Intro />
      <div style={{ zIndex: 2 }}>
        <About
          members={data?.members?.edges?.map(edge => ({
            id: edge?.node?.id,
            ...edge?.node?.frontmatter,
          }))}
          bio={pageData?.bio}
        />
        <Contact socialLinks={pageData?.['social_links']} />
        <Live
          shows={
            data?.shows?.edges?.map(edge => ({
              id: edge?.node?.id,
              ...(edge?.node?.frontmatter || {}),
            })) || []
          }
        />
        {testimonials && <Testimonials testimonials={testimonials} />}
        <SongList songs={data?.songs?.frontmatter?.songs || []} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Data {
    shows: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(shows)/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            address
            date(formatString: "YYYY-MM-DD")
            time
            tickets
            event
            venue
            city
            price
            description
          }
        }
      }
    }
    members: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(members)/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            firstName
            lastName
            role
            order
          }
        }
      }
    }

    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(testimonials)/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            author
            text
          }
        }
      }
    }

    songs: markdownRemark(frontmatter: { title: { eq: "main set" } }) {
      id
      frontmatter {
        title
        songs {
          artist
          title
        }
      }
    }

    pageInfo: markdownRemark(frontmatter: { title: { eq: "home page" } }) {
      id
      frontmatter {
        title
        bio
        social_links {
          name
          url
          icon
        }
      }
    }
  }
`

export default IndexPage
