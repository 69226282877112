import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './about.scss'

const About = ({ bio, members }) => {
  const paragraphs = bio.split('\n')
  return (
    <div className="white" id="about">
      <h2 className="text-center f-lg section-title">
        We Are Maximum Overdrive
      </h2>
      <div className="video-wrapper max-width-lg">
        <div className="video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vs2gCOQaEGk?si=o-8bxtzfCBkuWp-E"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
      <div className="video-wrapper max-width-lg">
        <div className="video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/v4T5LveasdU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
      <div className="max-width-content">
        <div className="text-center max-width-xl">
          {paragraphs?.length > 0 && paragraphs.map(p => <p key={p}>{p}</p>)}
        </div>
        <div className="the-band">
          <div className="band-photo">
            <StaticImage
              src="../../images/maximum-overdrive.jpg"
              width={1000}
              alt="Band Photo"
            />
          </div>

          <ul className="band-members">
            Featuring:
            {members
              .sort((a, b) => (a.order < b.order ? -1 : 1))
              .map(({ id, firstName, lastName, role }) => (
                <li key={id}>
                  {firstName} {lastName} - {role}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default About
