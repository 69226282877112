import React from 'react'
import dayjs from 'dayjs'

import './live.scss'
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

function isInTheFuture(date) {
  return dayjs(date, 'YYYY-MM-DD HH:mm A').isAfter(dayjs())
}

const Live = ({ shows }) => (
  <div className="white" id="shows">
    <div className="content">
      <h2 className="text-center section-title">Maximum Live</h2>
      <div className="show-list">
        {shows
          .filter(({ date, time }) => isInTheFuture(`${date} ${time}`))
          .sort((a, b) => (dayjs(a.date).isBefore(b.date) ? -1 : 1))
          .map(
            ({
              title,
              date,
              time,
              description,
              tickets,
              event,
              address,
              city,
              price,
              id,
              venue,
            }) => (
              <div key={id} className="show">
                <p className="title">
                  {title} {venue ? `@ ${venue}` : ''}
                </p>
                <div className="when">
                  <p>
                    {dayjs(date).format('MMM DD YYYY')} @ {time}
                  </p>
                </div>
                <div className="where">
                  {city && (
                    <p>
                      {city}
                      {', '}
                    </p>
                  )}
                  {address && <p>{address}</p>}
                </div>

                <div className="links">
                  {price && <p>{price}</p>}
                  <div>
                    {tickets && (
                      <a
                        href={tickets}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Tickets
                      </a>
                    )}
                    {event && (
                      <a href={event} target="_blank" rel="noopener noreferrer">
                        Event
                      </a>
                    )}
                  </div>
                </div>
                <p className="description">{description}</p>
              </div>
            )
          )}
      </div>
    </div>
  </div>
)

export default Live
