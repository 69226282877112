import React from 'react'
import SocialLinks from '../socialLinks/SocialLinks'

import './contact.scss'

const Contact = ({ socialLinks }) => (
  <div id="contact" className="white text-center">
    <div className="content">
      <h1>
        Book the Party. <br />
        Live the 80s.
      </h1>
      <p>
        For bookings and more information, please contact Maximum Overdrive at{' '}
        <a
          href="mailto:ultrasoundcanada@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          ultrasoundcanada@gmail.com
        </a>{' '}
        or <a href="tel:902.229.4817">902.229.4817</a>
      </p>
      <SocialLinks socialLinks={socialLinks} />
    </div>
  </div>
)

export default Contact
